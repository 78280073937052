import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import InvestigationStepContainer from './InvestigationStepContainer';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import Column from '../../../common/layout/Column';
import BooleanSelect from '../common/BooleanSelect';
import DateInput from '../../../common2/DateInput';
import Uploader from '../../../common/Uploader';
import ClaimStepClosableMessage from '../common/ClaimStepClosableMessage';
import Locale from '../../../Translate/Locale';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import InputHelpLink from '../InputHelpLink';
import CustomerCommentInput from '../common/CustomerCommentInput';
import useOnFormChange from '../../../../hooks/useOnFormChange';
import { useYupValidation } from '../../../../hooks/useYupValidation';

function InvestigationDeliveryInfoStep(props) {
  const { claim, onChange, onNext, ...restProps } = props;
  const { declarationOfRecipient, isDelivered } = claim;
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  const onInputChange = useOnFormChange(onChange);

  const config = getFormConfig(claim);
  const schema = createValidationSchema(config);
  const { deliveryDateInputName, isDeliveryDateDisabled } = config;

  const onDeliveredChange = useCallback(
    (value) => onChange({ isDelivered: value, ...(!value && { declarationOfRecipient: [], deliveryDate: null }) }),
    [onChange]
  );

  const { validate, errors } = useYupValidation(schema);

  const onClickNext = () => {
    if (validate(claim)) {
      onNext();
    }
  };

  const uploaderHelpLinkElement = (
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Declaration-of-Receipt-(DOR)-Requirements%C2%A0">
      Declaration of receipt requirements
    </InputHelpLink>
  );

  return (
    <InvestigationStepContainer {...restProps} onNext={onClickNext} isDisabled={isNextBtnDisabled}>
      <SidePanelContentHeader>Delivery information</SidePanelContentHeader>
      <Column spacing="medium">
        <ColumnsLayout>
          <BooleanSelect
            name="isDelivered"
            label="Delivery successful"
            value={isDelivered}
            isDisabled={isDeliveryDateDisabled}
            onChange={onDeliveredChange}
          />
          <DateInput
            name={deliveryDateInputName}
            label="Delivery date at carrier's hub"
            placeholder="Add date..."
            isRequired
            disabled={isDeliveryDateDisabled || !isDelivered}
            value={claim[deliveryDateInputName]}
            error={errors[deliveryDateInputName]}
            onChange={onInputChange}
          />
        </ColumnsLayout>
        <CustomerCommentInput claim={claim} onChange={onInputChange} />
        {!isDelivered ? (
          <ClaimStepClosableMessage intent="success">
            <Locale>CLAIMS_NO_ACTION_REQUIRED_CLICK_NEXT</Locale>
          </ClaimStepClosableMessage>
        ) : (
          <Uploader
            name="declarationOfRecipient"
            label="Declaration of recipient"
            topRightElement={uploaderHelpLinkElement}
            isRequired
            isDisabled={!isDelivered}
            files={declarationOfRecipient}
            errorWithTranslation={errors.declarationOfRecipient}
            allowedFileTypes={['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']}
            helpText="CLAIMS_DECLARATION_OF_RECIPIENT_UPLOAD_HELP_TEXT"
            onChange={onInputChange}
            onBusy={setIsNextBtnDisabled}
          />
        )}
      </Column>
    </InvestigationStepContainer>
  );
}

function getFormConfig(claim) {
  const { shipmentType } = claim;

  switch (shipmentType) {
    case 'outbound':
      return {
        deliveryDateInputName: 'deliveryDate',
        isDeliveryDateDisabled: false, // we don't request it for deadline check so we're asking for it now
      };
    case 'undeliverable_or_postal_return':
      return {
        deliveryDateInputName: 'postalReturnDeliveryDate',
        isDeliveryDateDisabled: true, // we already requested it for deadline check, no need to ask again
      };
    case 'return':
      return {
        deliveryDateInputName: 'deliveryDate',
        isDeliveryDateDisabled: true, // we already requested it for deadline check, no need to ask again
      };
    default:
      return {
        deliveryDateInputName: 'deliveryDate',
        isDeliveryDateDisabled: claim.shipmentId && !claim.isReturnShipment, // old behaviour without deadlines
      };
  }
}

function createValidationSchema(config) {
  const { deliveryDateInputName, isDeliveryDateDisabled } = config;

  return yup.object().shape({
    isDelivered: yup.boolean().when([], {
      is: () => !isDeliveryDateDisabled,
      then: (schema) => schema.required(),
    }),
    [deliveryDateInputName]: yup
      .date()
      .nullable()
      .when('isDelivered', {
        is: true,
        then: (schema) => schema.required(),
      })
      .label('Delivery date'),
    declarationOfRecipient: yup
      .array()
      .required()
      .when('isDelivered', {
        is: true,
        then: (schema) => schema.min(1),
      })
      .label('Declaration of recipient'),
  });
}

InvestigationDeliveryInfoStep.propTypes = {
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(InvestigationDeliveryInfoStep);

import React from 'react';
import ClaimsTableCell from './ClaimsTableCell';
import ClaimStatusBadge from '../../parcel-finder/ClaimStatusBadge/ClaimStatusBadge';
import ClaimsTypeBadge from './ClaimsTypeBadge';
import ClaimsValue from './ClaimsValue';
import TrackingCodeWithBadge from './TrackingCodeWithBadge';
import Text from '../../common/Text/Text';
import PlainButton from '../../common/PlainButton';
import ViewIcon from '../../../icons/view.svg';
import WarningIcon from '../../../icons/warning.svg';
import { customerActionTypeEnum, getActionTypeForClaim } from '../take-action/actionHelper';
import { formatDate } from '../../parcel-finder/cards/dateHelper';

import './ClaimsTableRow.scss';

const actionButtonPropsByType = {
  [customerActionTypeEnum.TAKE_CUSTOMER_ACTION]: {
    icon: WarningIcon,
    intent: 'warning',
    name: 'takeActionButton',
    children: 'Take action',
  },
  [customerActionTypeEnum.VIEW_DECLINED_REASON]: {
    icon: ViewIcon,
    name: 'viewDeclinedReasonButton',
    intent: 'primary',
    children: 'View reason',
  },
  [customerActionTypeEnum.DISPUTE_DECLINED_REASON]: {
    icon: ViewIcon,
    name: 'disputeDeclinedReasonButton',
    intent: 'primary',
    children: 'View reason',
  },
};

function ClaimsTableRow({ claim, onTakeActionButtonClick }) {
  const { status, claimType, netValueCurrency, netValue, createdAt, trackingCode, shipmentId, isNon7sShipment } = claim;

  const actionType = getActionTypeForClaim(claim);
  const actionButtonProps = actionButtonPropsByType[actionType];

  return (
    <tr data-testid="claim-table-row">
      <ClaimsTableCell itemType="status">
        <ClaimStatusBadge status={status} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="type">
        <ClaimsTypeBadge claimType={claimType} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="value">
        <ClaimsValue currency={netValueCurrency} value={netValue} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="date">
        <Text ink>{formatDate(createdAt)}</Text>
      </ClaimsTableCell>
      <ClaimsTableCell itemType="code">
        <TrackingCodeWithBadge trackingCode={trackingCode} isNon7sShipment={isNon7sShipment} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="action">
        <div>
          {shipmentId && (
            <a styleName="link" href={`/parcel-finder/shipments/${shipmentId}`} target="_blank">
              <PlainButton icon={ViewIcon} name="viewShipmentDetailButton">
                View details
              </PlainButton>
            </a>
          )}
          {actionButtonProps && <PlainButton {...actionButtonProps} onClick={() => onTakeActionButtonClick(claim)} />}
        </div>
      </ClaimsTableCell>
    </tr>
  );
}

export default ClaimsTableRow;

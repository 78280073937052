import React from 'react';

import ClaimsCenterSwitch from './ClaimsCenterSwitch';
import Loader from '../common/Loader/Loader';
import Heading2 from '../common/Heading2';
import SearchFilterNoResult from './search/SearchFilterNoResult';
import ClaimsCenterNoResults from './ClaimsCenterNoResults';
import ClaimsCenterResults from './ClaimsCenterResults';
import ClaimsPaginator from './ClaimsPaginator';

function ClaimsCenterFilterResults(props) {
  const {
    isLoading,
    error,
    claims,
    statuses,
    shipmentTypes,
    types,
    showSidePanel,
    showTakeActionPanel,
    totalClaimsCount,
    isLoadingMore,
    loadMore,
  } = props;

  return (
    <ClaimsCenterSwitch
      key="overview"
      isLoading={isLoading}
      error={error}
      data={claims}
      statuses={statuses}
      shipmentTypes={shipmentTypes}
      types={types}
    >
      <Loader key="loader" />
      <Heading2 key="error">Error loading claims</Heading2>
      <SearchFilterNoResult key="no-filter-results" />
      <ClaimsCenterNoResults key="no-results" onSubmitClaimButtonClick={showSidePanel} />
      <React.Fragment key="results">
        <ClaimsCenterResults claims={claims} onTakeActionButtonClick={showTakeActionPanel} />
        <ClaimsPaginator
          max={totalClaimsCount}
          value={claims.length}
          isLoading={isLoadingMore}
          onLoadMoreBtnClick={loadMore}
        />
      </React.Fragment>
    </ClaimsCenterSwitch>
  );
}

export default React.memo(ClaimsCenterFilterResults);

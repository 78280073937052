import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';

import ClaimsStatsOverview from './ClaimsStatsOverview';
import ClaimsCenterContainer from './containers/ClaimsCenterContainer';
import ClaimsHeaderContainer from './containers/ClaimsHeaderContainer';
import ClaimsPageHeader from './ClaimsPageHeader';
import AddIcon from '../../icons/add.svg';
import Button from '../common2/Button';
import PlainButton from '../common/PlainButton';
import SearchFilterContainer from './containers/SearchFilterContainer/SearchFilterContainer';
import Switch from '../Wizard/Switch';
import useClaimsCenterOverview from './useClaimsCenterOverview';
import ClaimsFilters from './ClaimsFilters';
import ClaimsSort from './ClaimsSort';
import useSearchClaims from './useSearchClaims';
import ClaimsSearchInput from './search/ClaimsSearchInput';
import SearchIcon from '../../icons/parcel-center-search.svg';
import ViewIcon from '../../icons/view.svg';
import Row from '../../components/common/Row';
import useModal from '../modal/useModal';
import TakeActionSidePanel from './take-action/TakeActionSidePanel';
import ClaimsCenterFilterResults from './ClaimsCenterFilterResults';
import ClaimsCenterSearchResults from './ClaimsCenterSearchResults';

function ClaimsCenterOverview(props) {
  const { claimsStats, statusCounts, reloadClaimsStats, showSubmitClaimPanel, history } = props;
  const {
    claims,
    isLoading,
    isLoadingMore,
    error,
    totalClaimsCount,
    statuses,
    shipmentTypes,
    types,
    sortBy,
    sortDir,
    setSortOptions,
    applyFilter,
    clearFilter,
    loadMore,
    reloadClaims,
  } = useClaimsCenterOverview();

  const {
    trackingCode,
    searchedClaims,
    isLoaded,
    isSearchPending,
    searchClaim,
    setTrackingCode,
    resetSearch,
  } = useSearchClaims(history);

  const getViewMode = () => (trackingCode === null ? 'filters' : 'search');
  const setViewMode = (viewMode) => setTrackingCode(viewMode === 'filters' ? null : '');

  const viewMode = getViewMode();

  const [takeActionClaim, showTakeActionPanel, closeTakeActionPanel] = useModal();

  useEffect(() => {
    if (trackingCode) searchClaim(trackingCode);
  }, []);

  const reloadClaimsCenterData = useCallback(() => {
    reloadClaims();
    reloadClaimsStats();
  }, [reloadClaims, reloadClaimsStats]);

  const onSearchButtonClick = useCallback(() => {
    setViewMode('search');
  }, [setViewMode]);

  const onViewFiltersButtonClick = useCallback(() => {
    resetSearch();
    setViewMode('filters');
  }, [setViewMode, resetSearch]);

  const refreshClaimsAfterSubmitTakeAction = useCallback(() => {
    if (viewMode === 'search' && trackingCode) {
      searchClaim(trackingCode);
    }

    reloadClaimsCenterData();
  }, [viewMode, reloadClaimsCenterData, searchClaim, trackingCode]);

  return (
    <ClaimsCenterContainer>
      <ClaimsHeaderContainer>
        <ClaimsPageHeader>
          <Button onClick={showSubmitClaimPanel} icon={AddIcon}>
            Submit a claim
          </Button>
        </ClaimsPageHeader>
        <ClaimsStatsOverview claimsStats={claimsStats} />
        <SearchFilterContainer>
          <Switch selected={viewMode}>
            <React.Fragment key="filters">
              <ClaimsFilters
                statuses={statuses}
                types={types}
                statusCounts={statusCounts}
                shipmentTypes={shipmentTypes}
                onApply={applyFilter}
                onClear={clearFilter}
              />
              <Row spacing={10}>
                <ClaimsSort sortBy={sortBy} sortDir={sortDir} onSort={setSortOptions} />
                <PlainButton icon={SearchIcon} onClick={onSearchButtonClick}>
                  Search by tracking code
                </PlainButton>
              </Row>
            </React.Fragment>
            <React.Fragment key="search">
              <PlainButton icon={ViewIcon} onClick={onViewFiltersButtonClick}>
                View filtering and sort options
              </PlainButton>
              <ClaimsSearchInput
                key="search"
                value={trackingCode}
                onSearchClaim={searchClaim}
                onSetTrackingCode={setTrackingCode}
                onResetSearch={resetSearch}
              />
            </React.Fragment>
          </Switch>
        </SearchFilterContainer>
      </ClaimsHeaderContainer>
      <Switch selected={viewMode}>
        <ClaimsCenterFilterResults
          key="filters"
          isLoading={isLoading}
          error={error}
          claims={claims}
          statuses={statuses}
          shipmentTypes={shipmentTypes}
          types={types}
          showSidePanel={showSubmitClaimPanel}
          showTakeActionPanel={showTakeActionPanel}
          totalClaimsCount={totalClaimsCount}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
        />
        <ClaimsCenterSearchResults
          key="search"
          isLoaded={isLoaded}
          isSearchPending={isSearchPending}
          searchedClaims={searchedClaims}
          showTakeActionPanel={showTakeActionPanel}
        />
      </Switch>
      <TakeActionSidePanel
        claim={takeActionClaim}
        onClose={closeTakeActionPanel}
        onSubmitSuccess={refreshClaimsAfterSubmitTakeAction}
      />
    </ClaimsCenterContainer>
  );
}

export default React.memo(withRouter(ClaimsCenterOverview));
